@tailwind base;
@tailwind components;
@tailwind utilities;

.scrollbar-none::-webkit-scrollbar {
	display: none;
};

.scrollbar-none {
	-ms-overflow-style: none;
	scrollbar-width: none;
};
